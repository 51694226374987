import Vue from "vue"
import store from "./store/index"
import router from "./router"
import axios from "axios"
import App from "./App.vue"
import BootstrapVue from "bootstrap-vue"
import "@/assets/scss/main.scss"
import "../public/css/all.min.css"
import VueClipboard from "vue-clipboard2"
import Donut from "vue-css-donut-chart"
import "vue-css-donut-chart/dist/vcdonut.css"

import {
  refreshTokensAndRetry,
  getAccessToken,
  getSchemaName,
  clearStorage
} from "@/utils/authenticationManager"
import { initCustifyTracker } from "@/utils/custifyManager"
import GlobalMixin from "@/mixins/globalMixins"
import vClickOutside from "v-click-outside"

Vue.use(vClickOutside)

const BASE_URL = process.env.VUE_APP_BASEURL

if (
  navigator &&
  navigator.serviceWorker &&
  navigator.serviceWorker.getRegistrations
) {
  // Removes existing service workers, so they no longer take control of the cache!
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
}

axios.defaults.xsrfHeaderName = "X-CSRFToken"

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded"

axios.interceptors.request.use(
  (config) => {
    // Check if the URL is for S3 bucket
    if (config.url.includes("s3.amazonaws.com")) {
      // Remove Authorization header for S3 requests
      delete config.headers.common.Authorization
    } else {
      // Add Authorization header for non-S3 requests
      config.headers.common.Authorization = `Bearer ${getAccessToken()}`
    }
    if (getSchemaName()) {
      config.headers.common["Schema-Name"] = `${getSchemaName()}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  /*
    Here we are handling bad response from the server,
    specifically 401 where token is not valid because either it has
    a) expired or b) wrong token

    If the token is expired we refresh-Token-And-Retry the request.
    But if token is expired and api/token/refresh/ gives bad token response,
    this means that the refresh token is itself expired, and user should be
    "logged out".
  */
  (response) => response,
  (error) => {
    const originalRequest = error.config
    const { status, data } = error.response
    const isBadToken = status === 401 && data.code === "token_not_valid"
    const isRefreshRequest =
      originalRequest.url === `${BASE_URL}api/token/refresh/`

    if (isBadToken && isRefreshRequest) {
      clearStorage()
      if (!window.location.pathname.startsWith("/login")) {
        window.location.assign(`${BASE_URL}${window.location.search}`)
      }
    } else if (isBadToken) {
      return refreshTokensAndRetry(originalRequest)
    }
    return Promise.reject(error)
  }
)

Vue.config.productionTip = false

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(Donut)
Vue.use(BootstrapVue)

Vue.use(store)
store.dispatch("I18nStore/getI18nData")

Vue.mixin(GlobalMixin)

new Vue({
  router,
  render: (h) => h(App),
  store
}).$mount("#app")

if (window.location.hostname !== "localhost") {
  initCustifyTracker(process.env.VUE_APP_CUSTIFY_ACCOUNT_ID)
}
