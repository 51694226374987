import axios from "axios"

export const createMeetingBotApi = async (payload) => {
  // create meeting bot for recording meeting data
  const apiUrl = "/api/meeting/create_meeting"
  const resp = await axios.post(apiUrl, payload)
  return resp
}

export const fetchCallMeetingDataApi = async (params) => {
  // fetch call meeting data (video_url, summary, speaker list, transcript)
  const apiUrl = "/api/meeting/get_meeting_data"
  const resp = await axios.get(apiUrl, { params })
  return resp
}

export const fetchMeetingParticipantsApi = async (params) => {
  // fetch meeting participants
  const apiUrl = "/api/meeting/get_meeting_participants"
  const resp = await axios.get(apiUrl, { params })
  return resp
}

export const fetchMeetingBotStatusApi = async (params) => {
  // fetch meeting bot status - connected, not_connected, connecting
  const apiUrl = "/api/meeting/get_meeting_bot_status"
  const resp = await axios.get(apiUrl, { params })
  return resp
}

export const removeBotFromMeetingApi = async (payload) => {
  // remove bot from meeting to stop recording of meeting
  const apiUrl = "/api/meeting/remove_meeting_bot"
  const resp = await axios.post(apiUrl, payload)
  return resp
}

export const pauseMeetingRecordingApi = async (payload) => {
  // pause recording of the meeting for given bot
  const apiUrl = "/api/meeting/pause_meeting_recording"
  const resp = await axios.post(apiUrl, payload)
  return resp
}

export const resumeMeetingRecordingApi = async (payload) => {
  // resume recording of the meeting for given bot
  const apiUrl = "/api/meeting/resume_meeting_recording"
  const resp = await axios.post(apiUrl, payload)
  return resp
}

export const getTodayEventsApi = async (params) => {
  // get all future meetings of today
  const apiUrl = "/api/user_calendar/get_today_events"
  const resp = await axios.get(apiUrl, {params})
  return resp
}