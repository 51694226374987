import axios from "axios"
import { realTimeAssistantService } from "@/apps/call/RealTimeAssistantComponents/index.js"

const getDefaultState = () => {
  return {
    call: null,
    callTranscriptData: null,
    summaryDataLoading: {},
    currentSummaryTab: "",
    transcriptDataLoading: false
  }
}

const state = getDefaultState()

const getters = {
  getCall (state) {
    return state.call
  },
  getCallTranscriptData (state) {
    return state.callTranscriptData
  },
  getTranscriptDataLoading (state) {
    return state.transcriptDataLoading
  },
  getSummaryDataLoading (state) {
    return state.summaryDataLoading
  },
  canTriggerSummary (state) {
    return !!state.call &&
        state.call.transcription &&
        state.call.transcription.response_data &&
        state.call.duration > 60000
  },
  getTranscriptSummary (state) {
    if (state.call) {
      return state.call.transcriptSummary
    }
    return null
  },
  getCurrentSummaryTab (state) {
    return state.currentSummaryTab
  }
}
const mutations = {
  setCall (state, value) {
    state.call = value
  },
  updateCall (state, value) {
    state.call = { ...state.call, ...value }
  },
  setTranscriptDataLoading (state, value) {
    state.transcriptDataLoading = value
  },
  setCallTranscriptData (state, value) {
    state.callTranscriptData = value
  },
  resetCallAudioStore (state) {
    Object.assign(state, getDefaultState())
  },
  setSummaryTab (state, summaryTab) {
    state.currentSummaryTab = summaryTab
  }

}

const actions = {

  setSummaryTab ({ commit }, { summaryTab }) {
    commit("setSummaryTab", summaryTab)
  },
  fetchCallTranscriptData ({ commit }, { callId }) {
    realTimeAssistantService.methods.getTranscriptSummary(callId).then(data => {
      commit("setCallTranscriptData", data[0])
    }).catch(error => {
      console.debug(error)
    })
  },
  async getCallById ({ commit }, { callId }) {
    try {
      const response = await axios.get(`/api/calls/${callId}/get_bao_audio_call/`)
      commit("setCall", response.data)
    } catch (error) {
      console.debug(error)
    }
  },
  async getBaoAudioSegments ({ commit }, { callId }) {
    try {
      commit("updateCall", { audioLoading: true })
      const { data } = await axios.get(`/api/callAudioData/?id=${callId}`)
      if (data.final_audio_url === null) {
        data.transcription = { response_data: null }
      }
      commit("updateCall", { ...data, audioLoading: false })
    } catch (error) {
      commit("updateCall", { audioLoading: false, transcription: { response_data: null } })
      console.debug(error)
    }
  },
  async requestAudioTranscripts ({ commit }, { callId }) {
    try {
      commit("updateCall", { transcriptLoading: true })
      const { data } = await axios.get(`/api/callAudioData/get_complete_transcription/?id=${callId}`)
      if (data) {
        const transcription = { response_data: data.sentences }
        commit("updateCall", { transcription, transcriptLoading: false })
      }
    } catch (error) {
      console.debug(error)
      commit("updateCall", { transcriptLoading: false })
    }
  },
  async requestTranscriptSummary ({ commit, state }, { callId, summaryFormat, regenerate = false }) {
    console.log("requestTranscriptSummary", callId, summaryFormat, regenerate)
    try {
      const summaryLoading = state.summaryDataLoading
      summaryLoading[summaryFormat] = true
      commit("updateCall", { summaryDataLoading: summaryLoading })
      const params = {
        id: callId,
        summary_format: summaryFormat,
        regenerate
      }
      const { data } = await axios.get("/api/callAudioData/get_transcription_summary/", { params })
      summaryLoading[summaryFormat] = false
      const summaryData = { ...state.call.transcriptSummary, ...data }
      commit("updateCall", { transcriptSummary: summaryData, summaryDataLoading: summaryLoading })
      console.log("summaryData", summaryData)
    } catch (error) {
      const summaryLoading = state.summaryDataLoading
      summaryLoading[summaryFormat] = false
      commit("updateCall", { summaryDataLoading: summaryLoading })
      console.debug(error)
    }
  },
  async saveCallTranscripts ({ commit, dispatch }, { callTranscript, shouldCheckForTranscript }) {
    /**
     * saves calculated transcript data for call to database
     * params: call_transcript
     */
    commit("setTranscriptDataLoading", true)

    try {
      const { data } = await axios.post(realTimeAssistantService.callTranscriptUrl, {
        ...callTranscript,
        shouldCheckForTranscript
      })
      const callAudioData = { ...data.call_transcript, call_items_transcripts: data.call_items_transcripts }
      commit("setCallTranscriptData", callAudioData)
    } catch (error) {
      console.debug(error)
    }
    commit("setTranscriptDataLoading", false)
  },
  async getRegeneratedTranscriptsAndSummary ({ getters, commit, dispatch, state }, { callId, audioInputLanguage }) {
    try {
      commit("updateCall", { newTranscriptLoading: true })
      const { data } = await axios.get(`/api/callAudioData/get_recreated_tx?tx_lang=${audioInputLanguage}&id=${callId}`)
      if (data) {
        const transcription = { response_data: data.sentences }
        commit("updateCall", { transcription, newTranscriptLoading: false })
        // reset the summary value stored in vuex store
        commit("updateCall", { transcriptSummary: {} })
        if (getters.canTriggerSummary) dispatch("requestTranscriptSummary", { callId, summaryFormat: state.currentSummaryTab })
      }
    } catch (error) {
      commit("updateCall", { newTranscriptLoading: false })
      console.debug(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
