import axios from "axios"
import { networkController } from "@/services/networkController"
import { utils } from "@/apps/call"
import store from "@/store"

const getDefaultState = () => {
  return {
    callSummary: {}, // This ensures that feedback page doesn't throw console errors when doing type check
    callDetails: null,
    callNotes: "",
    summaryLoading: false,
    playbookItems: [],
    currentSummaryText: ''
  }
}

const state = getDefaultState()

const getters = {
  getCallSummary (state) {
    return state.callSummary
  },
  getCallDetails (state) {
    return state.callDetails
  },
  getCallNotes (state) {
    return state.callNotes
  },
  getSummaryLoading (state) {
    return state.summaryLoading
  },
  getPlaybookItems (state) {
    return state.playbookItems
  },
  isUserCreator: (state, getters, rootState) => (created_by_id = null) => {
    if(!created_by_id){
      created_by_id = state.callDetails && state.callDetails.created_by ? state.callDetails.created_by.pk : null
    }
    return created_by_id === rootState.auth.user.pk
  },
  getCurrentSummaryText (state) {
    return state.currentSummaryText
  }
}

const mutations = {
  setCurrentSummaryText (state, summaryText) {
    state.currentSummaryText = summaryText
  },
  setCallSummary (state, callSummary) {
    state.callSummary = callSummary
    state.callNotes = callSummary.notes
  },
  setSummaryLoading (state, value) {
    state.summaryLoading = value
  },
  setCallNotes (state, value) {
    state.callNotes = value
  },
  setCounterParts (state, value) {
    state.callSummary.counterparts = value
  },
  resetCallSummaryStore (state) {
    Object.assign(state, getDefaultState())
  },
  setCallDetails (state, call) {
    state.callDetails = call
    const callItems = call.call_items
    const playbook = call.talkscript
    if (playbook) {
      const attachCallItem = (playbookItem, callItems) => {
        // no call items, we just return the playbookItem as it is
        if (!callItems || !callItems.length) return playbookItem

        // 1. get the call items for this particular playbook based on the
        // talkscript_item field and the playbookItem.id
        callItems = callItems.filter(ci => ci.talkscript_item === playbookItem.id)

        // could be a bug, could be unfortunate circumstances (race condition much?!)
        if (callItems.length > 1) console.error("Found more than 1 call item -> investigate!")

        // 2. call items should be sorted by recency anyway (descending) - so we take the first one
        playbookItem.call_item = callItems[0]
        if (
          playbookItem.call_item &&
          playbookItem.call_item.loaded_additional_data &&
          playbookItem.call_item.loaded_additional_data.length > 0
        ) {
          // 3. if the call item contains loaded_additional_data we also have to attach that to the playbook item
          // then do the same for all playbook items
          playbookItem.loadedData = playbookItem.call_item.loaded_additional_data.map(item => {
            const newItem = { ...item }
            newItem.open = true // the state of newly loaded objections/dyn. playbooks should be open
            newItem.selected_choice.workflow.children = item.selected_choice.workflow.children.map(
              // do the same for all playbook items in the loaded data
              addItem => attachCallItem(addItem, newItem.call_items)
            )
            return newItem
          })
        }
        return playbookItem
      }
      const playbookItemWithCallItems = call.talkscript.main_container.children.map(
        item => {
          if (callItems && !!callItems.length) {
            return attachCallItem(item, callItems.filter(callItem => {
              return callItem.additional_data === null
            }))
          }
          return item
        }
      )
      state.playbookItems = playbookItemWithCallItems
    }
  }
}

const actions = {

  async setCurrentSummaryText({commit}, { summaryText }) {
    commit("setCurrentSummaryText", summaryText)
  },

  async fetchCallSummary ({ commit }, { callId, showLoader = true }) {
    if (showLoader) commit("setSummaryLoading", true)
    try {
      const response = await axios.get(`/api/calls/${callId}/get_summary`)
      commit("setCallSummary", response.data)
    } catch (error) {
      console.debug(error)
    }
    commit("setSummaryLoading", false)
  },
  async fetchCallDetails ({ commit }, { callId }) {
    try {
      const response = await axios.get(`/api/calls/${callId}`)
      commit("setCallDetails", response.data)
    } catch (error) {
      console.debug(error)
    }
  },
  saveCallNotes ({ state }, { callId }) {
    const userPK = store.getters["auth/user"].pk
    try {
      const callDetails = {
        user: userPK,
        talkscript: state.callSummary.talkscript.id,
        notes: state.callNotes
      }
      axios.put(`/api/calls/${callId}`, callDetails)
    } catch (error) {
      console.debug(error)
    }
  },
  saveCounterparts ({ state }, { callId }) {
    const userPK = store.getters["auth/user"].pk
    try {
      const callDetails = {
        user: userPK,
        talkscript: state.callSummary.talkscript.id,
        counterparts: state.callSummary.counterparts
      }
      axios.put(`/api/calls/${callId}`, callDetails)
    } catch (error) {
      console.debug(error)
    }
  },
  async handleCallItemChanged ({ getters, commit, dispatch }, { item, callId }) {
    /***
     * saves/updates call item and refreshes call summary
     */
    try {
      await dispatch("saveCallItem", { item, callId })
    } catch (error) {
      console.debug(error)
    }
  },
  saveCallItem ({ dispatch, getters }, { item, callId }) {
    /***
     * saves(creates/updates) the callItem for the given item and updates
     * the item accordingly
     */
    return new Promise((resolve, reject) => {
      // extract the callItem data from the item
      const callItemData = utils.getCallItemDataToSave(item, callId)
      // prepare the request-callback for saving the callItem
      const request = utils.getCallItemSaveRequest(item, callItemData)
      // send the request via networkController(to avoid race condition)
      item.callItemPromise = networkController.methods.sendRequest(item.uniqueId, request)
      item.callItemPromise.then((callItemResponse) => {
        // update the call_item with recent data.
        item.call_item = callItemResponse.data
        resolve(item)
      }).catch(error => {
        dispatch("handleError", error.response)
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
