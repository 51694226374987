import Vue from "vue"
import { mapMutations } from "vuex"

const GlobalMixin = Vue.mixin({
  computed: {
    isApplicationInIFrame () {
      return window.self !== window.top
    }
  },
  methods: {
    ...mapMutations({
      hideDashboardMenu: "dashboardStore/hideDashboardMenu",
      setToastActiveState: "toast/setToastActiveState",
      setToastType: "toast/setToastType",
      setToastMessage: "toast/setToastMessage"
    }),
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    pushOrReplaceRoute (routeUrl) {
      if (this.isApplicationInIFrame) return this.$router.replace(routeUrl)
      else return this.$router.push(routeUrl)
    },
    toPascalCase (str) {
      if (str.length < 1) return
      // group_number_1 => Group Number 1
      return str.replaceAll("_", " ").split(" ").map(word => word[0].toUpperCase().concat(word.slice(1))).join(" ")
    },
    mixinHideDashboardMenu () {
      this.hideDashboardMenu()
    },
    showGlobalToast ({ status, message }) {
      this.setToastActiveState(true)
      this.setToastType(status)
      this.setToastMessage(message)
    },
    hideGlobalToast () {
      this.setToastActiveState(false)
      this.setToastMessage(null)
    },
    evalString (text) {
      return text.replace(/{{.*?}}/g, match => {
        const variable = match.slice(2, -2)
        // eslint-disable-next-line no-eval
        return eval("this." + variable)
      })
    },
    async areObjectsEqual (object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)
      if (keys1.length !== keys2.length) {
        return false
      }
      for await (const key of keys1) {
        if (!object2[key]) {
          return false
        }
        if (object1[key].toString() !== object2[key].toString()) {
          return false
        }
      }
      return true
    },
    isValidNumber (number) {
      return (!isNaN(Number(number)) && number !== null) || number === 0
    },
    messageBaoSwift (message) {
      if (window.parent && window.parent.postMessage) {
        window.parent.postMessage(message, "*")
      }
    }
  }
})

export default GlobalMixin
